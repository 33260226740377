<template>

  <div>
    <bread-crumb class="detail center"></bread-crumb>
    <iframe :src="url" style="width: 100%; height: 555px;"></iframe>
  </div>
</template>

<script>
import {getDyTab} from "@/api/homeService";
import BreadCrumb from "@/components/common/breadCrumb";

export default {
  name: "customPage",
  components: {BreadCrumb},
  props: {},
  data() {
    return {
      url: ""
    }
  },
  mounted() {
    getDyTab(this.$route.query.zrzyDyId).then(res => {
      if (res.code === '0') {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].id === this.$route.query.tabId) {
            this.url = res.data[i].wbdz
          }
        }
      }
    })
  }
}
</script>

<style scoped>

</style>
